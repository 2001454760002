import { alpha } from "@mui/material"

export function GetThemableClassName(Theme,ClassName)
{

  if (Theme?.palette?.mode==="dark")
  {
    return ClassName+'_dark'
  }
  else
  {
    return ClassName
  }

}

export function GetThemableColor(Theme,Color)
{
  
  if (Theme?.palette?.mode==="dark")
  {
    switch (Color)
    {
      case "gold":
      case 'Gold':
        Color='#ffd700'
        break
      
      case "silver":
      case "Silver":
        Color='#c0c0c0'
        break;
      
      default:
        //console.log("GetThemableColor "+Color)
    }
    return alpha(Color,0.6)
  }
  else
  {
    return Color
  }

}