import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { ReferenceUserContext } from "./UserContextReference";
import { HorizontalTrackSwipe } from "./TrackSwipe";
import { useState } from "react";
import { GetThemableClassName } from "./CssUtils";
import { useTheme } from "@emotion/react";

export default function TrendIn42Stats(Props)
{
  const Theme=useTheme()
  const ContextState=useContext(ReferenceUserContext).ContextState
  const [LastPlayed,SetLastPlayed]=useState(ContextState?.LastPlayed)

  useEffect(()=>
  {
    SetLastPlayed(ContextState?.LastPlayed)
  },[ContextState])
  
  /* return <Card>
      <CardHeader className={GetThemableClassName(Theme,"CardTitle")} title="Recently listened in 42Stats" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid key="LastPlayed" item xs={12}>
            <HorizontalTrackSwipe className="swiper"  Tracks = {LastPlayed}/>
          </Grid>
        </Grid>
      </CardContent>
    </Card> */
    return <Card>
      <CardHeader className={GetThemableClassName(Theme,"CardTitle")} title="Recently listened in 42Stats" />
      <CardContent>
        <HorizontalTrackSwipe className="swiper"  Tracks = {LastPlayed}/>
        
      </CardContent>
    </Card>
  // 
      
}