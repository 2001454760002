import React, { useEffect, useState } from "react";
import { GetUserCachedFile } from "./UserState";
import { Card, CardActionArea, CardContent, CardHeader, Collapse, Grid, LinearProgress, Link, Stack, Typography } from "@mui/material";
import { Duration} from "luxon";
import { AnimatedValue } from "./AnimatedValue";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GetThemableClassName, GetThemableColor } from "./CssUtils";
import { useTheme } from "@emotion/react";

const HISTORY_TYPE_LAST_MONTH = 1
const HISTORY_TYPE_LAST_QUARTER = 3
const HISTORY_TYPE_LAST_YEAR = 12
const HISTORY_FILE_ERROR = 0

const Top5Colors = [ "#FFD700", "#C0C0C0", "#CD7F32", "#E6E6FA", "#FFB6C1"]
const Top5Ext = [ "st", "nd", "rd", "th", "th"]


function GetStatCard(Key,Data)
{
  const KeyInfo={
      'ListenDuration':{Class: "Length","Header":"You listened during"},
      'TotalListenCount' : {Class: "Tracks","Header":"Total Number of tracks you listened"},
      'TotalTracksCount' : {Class: "Tracks","Header":'Number of distinct tracks you listened'},
      'TotalAlbumCount' : {Class: "Albums","Header":"Number of albums you listened"},
      'TotalArtistCount' : {Class: "Artists", "Header":"Number of artists you listened"}
  }

  const IsDuration = (Key === 'ListenDuration')
  let TypographyType = IsDuration?"h4":"h3"
  console.log("GetStatCard",Key,Data[Key])
  return <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Card >
        <CardHeader className={"Indicator"+KeyInfo[Key].Class+"H"} title={KeyInfo[Key].Header}/>
        <CardContent className={"Indicator"+KeyInfo[Key].Class}>
          <Typography variant={TypographyType}>
            <AnimatedValue key={'TopStat_'+Math.random()} Value={Data[Key]} AutoStart={true} IsDuration={IsDuration}/>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
}

function GetTopStackElement(Element,index)
{
  let StackElement = null
  switch (Element?.Style)
  {
    case 0:
      StackElement=<TopStatPanel key={Element?.Title+index} {...Element} />
      break;
    case 1:
      StackElement=<TopHistoryPanel key={Element?.Title+index} {...Element} />
      break;
    default:
      break;
  }
  return <Grid item xs={12} spacing={2} direction="row">
      {StackElement}
    </Grid>
}

function HistoryLoading()
{
  return <Stack>
    <Typography>Loading...</Typography>
    <LinearProgress sx={{height:'5px',width:'100%'}}/>
  </Stack>
}

export default function UserHistoryRecap(Props)
{
  const Type = Props?.RefFileType
  const [URL] = useState( Props?.FileName)
  const [PanelData,SetPanelData] = useState(null)

  function GetDataFile()
  {
    const FileURL = GetUserCachedFile(URL)
    let opts = {
      method: 'GET',
      //mode: 'no-cors',
      credentials: 'include',
    };
    fetch(FileURL,opts).then(function (response) {
      //console.log("opaque response",response.json());
      return  response.clone().json();
    }).then( (body)=>{
      //console.log("body of file content",body)
      SetPanelData(body)
      return
    })
  
  }

  useEffect(GetDataFile,[URL])

  if (!Type || !URL)
  {
    return null;
  }

  switch (PanelData?.Type)
  {
    case HISTORY_TYPE_LAST_MONTH:
    case HISTORY_TYPE_LAST_QUARTER:
    case HISTORY_TYPE_LAST_YEAR:
      return <TopHistoryStack key={PanelData?.Title} {...PanelData} />

    case HISTORY_FILE_ERROR:
      return <Typography>File does exist yet. Come back in a few days, this section will start to populate itself...</Typography>
        
    default:
      return <HistoryLoading />
  }

}

function TopHistoryPanel (Props)
{
  const Theme = useTheme()
  const HMDuration = require ("humanize-duration");
  
  let TopPanels=!(Props?.Data)?null:Props?.Data.map((t, index)=>{

  const ListenSeconds = parseInt(t?.ListenDuration,10)
  
  const DurationString = HMDuration(Duration.fromObject({seconds:ListenSeconds}),{largest:2});

  return <Grid item xs={15} sm={15} md={7} lg={5} xl={3}>
      <Card sx={{backgroundColor: GetThemableColor(Theme,Top5Colors[index])}}>
        <CardHeader title={(index+1)+Top5Ext[index]} sx={{fontSize:"2em"}}/>
        <CardContent>
          <Grid key={"TopItem_"+index} container  justifyContent="center" alignContent="stretch" >
            <Grid item xs={12}>
              <Stack direction="column" spacing={2}>
                <Typography variant="h4">{t?.Name}</Typography>
                <Link href={t?.URL} target='42StatsDeezer' rel="noopener">
                  <img src={t?.Picture+'?size=big'} alt={t?.Name} width={"256px"} />
                </Link>
                <Typography>Listened for : {DurationString}</Typography>
                <Typography>Listened : {t?.ListenCount} times</Typography>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  
  })

  if (!TopPanels)
  {
    TopPanels=<Typography>This section is empty. There is not enough listening history on 42Stats. This will fill you have been around for long enough</Typography>
  }

  return <Card >
    <CardHeader className={GetThemableClassName(Theme,"CardTitle")} title={Props?.Title} />
    <CardContent>
      <Grid container spacing={2} justifySelf="center" alignItems="stretch" columns={15} >
        {TopPanels}
      </Grid>
    </CardContent>
  </Card>
}

function TopHistoryStack(Props)
{
  const Theme = useTheme()
  const [ShowContent,SetShowContent]=useState(true)
  const Stacks=Props?.Data.map(GetTopStackElement)

  function ChangeShowContent()
  {
    SetShowContent( !ShowContent)
  }

  const Icon= ShowContent?<ExpandLessIcon onClick={ChangeShowContent}/>:<ExpandMoreIcon onClick={ChangeShowContent}/>
  const HeaderText=<Grid container alignContent="center" pacing={1}>
      <Grid item xs={11} >{Props?.Title}</Grid>
      <Grid item xs={1} alignContent="flex-end"> 
        {Icon}
      </Grid>
      </Grid>

  return <Card >
    <CardHeader className={GetThemableClassName(Theme,"CardTitle")} title={HeaderText} />
    <Collapse in={ShowContent}>
      <CardContent>
        <Grid container spacing={2} justifyContent="stretch" alignItems="flex-start">
          {Stacks}
        </Grid>
      </CardContent>
    </Collapse>
  </Card>
}

function TopStatPanel(Props)
{

  console.log("StatPanel",Props)
  const Data = Props?.Data[0]
  const Class= Props?.Class?Props.Class:Props?.Name
  const CardHeaderClass = "Indicator"+Class+'H'
  const CardClass = "Indicator"+Class
  let Content=null
  
  Content= Object.keys(Data).map((v)=>
    {
      return GetStatCard(v,Data)
    })
   
  return <Grid key={"UserIndicator_"+ Math.random()} item  >      
            <Card sx={{minWidth:"200px"}}>
              <CardHeader className={CardHeaderClass} title= {Props?.Name} > </CardHeader>
              <CardContent className={CardClass}>
                <Grid container spacing={2} justifyContent="center" alignItems="center" >
                  {Content}
                </Grid>
              </CardContent>
              <CardActionArea ></CardActionArea>
            </Card>
           
          </Grid>
}