import React, { useContext, useEffect, useState } from "react";
import { ReferenceUserContext } from "./UserContextReference";
import { Alert, Box, Button, FormControl, Grid, LinearProgress, ListItemIcon, ListItemText, Stack, TextField, Typography } from "@mui/material";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import SendIcon from '@mui/icons-material/Send';
import MessageIcon from '@mui/icons-material/Message';

import DonateButton from "./DonateButton";
import { GetApiURL } from "./UserState";

export function FeedbackMenu(open)
{
  const {  ContextState } = useContext(ReferenceUserContext);
  
  let Text = "Feedback";

  if (ContextState?.UserInfo && ContextState.UserInfo.id)
  {
    return ( 
      <>
        <ListItemIcon
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
        >
        <VolunteerActivismIcon />
        </ListItemIcon>
        <ListItemText primary={Text} sx={{ opacity: open ? 1 : 0 }} />
      </>
    
    );
  }
  else
  {
    return null;
  }
  
}

 export default function Feedback()
{

  const {  ContextState } = useContext(ReferenceUserContext);
  


  if (ContextState?.UserInfo && ContextState.UserInfo.id)
  {
    return ( 
      <>
        <Grid container spacing={2} >
          <Grid item xs={12} md={6}>
            <MessagePosterForm Context={ContextState} />
          </Grid>
          <Grid item xs={12} md={6} >
            <Stack spacing={3}>
              <Typography>
                If you wish to donate to help sustain and develop 42Stats website, and playlists generation, just press the Donate button.
              </Typography>
              <DonateButton />
            </Stack>
          </Grid>
        </Grid>
      </>
    
    );
  }
  else
  {
    return null
  }
}

function MessagePosterForm(Props)
{
  
  const [HasText,SetHasText] = useState(false)
  const [TextComment,SetTextComment] = useState()
  const [PostingRequest,SetPostingRequest]=useState(false)
  const [PostingStatus,SetPostingStatus] = useState(null)
  
  const TextLabel=<div><MessageIcon/>Message... </div>

  useEffect(()=>{

    if (TextComment?.length ^ HasText)
    {
      SetHasText(TextComment?.length!==0)
    }

  },[TextComment, HasText])


  function HandleSendFeedBack(e)
  {
    if (!TextComment?.length)
    {
      SetHasText(false)
      SetPostingRequest(false)
      return
    }
    
    const URL = GetApiURL("Verb=PostFeedBack")
    SetPostingRequest(true)
    fetch(URL, {
          method: "POST",
          'Content-Type': 'application/x-www-form-urlencoded',
          credentials: 'include',
          //mode: "no-cors",
          //make sure to serialize your JSON body
          body: JSON.stringify({
            FeedBack : TextComment,
            SessionId: Props.Context.SessionId,
            Nonce: Props.Context.Nonce
          })
         
    })
    .then( (response) => { 
      if (response?.status === 200)
      {
        return response.clone().text()        
      }
      else
      {
        console.log("Message posted OK");
      }
    }).then( (body)=>
    {
      console.log("post return body",body) 
      try
      {
        let Result = JSON.parse(body)

        if (Result.FeedBackStatus)
        {
          SetPostingStatus({Text:"Message posted, Thank you",Success:true})
        }
        else
        {
          SetPostingStatus({Text:"Sorry, something wrong happened. Refresh the page and try again",Success:false})
        }
      }
      catch (e)
      {
        SetPostingStatus({Text:"Sorry, something wrong happened. Refresh the page and try again",Success:false})
      }
      setTimeout(()=>{
        SetPostingRequest(false)
        setTimeout(()=>{SetPostingStatus(null)},5000)
      },1500)
      SetTextComment(null)
      return 
    });
  }
  
  if (PostingRequest)
  {
    return <Stack spacing={2}>
        <Typography>Processinsg....</Typography>
        <LinearProgress sx={{width:'100%',height:"3px"}}/>
          
        
      </Stack>
  }
  else
  {
    return <FormControl flex='grow'>
      <Stack spacing={2}  sx={{display:'flex',height:'50vh'}}>
        <Typography> If you wish to send some Feedback, ask questions, submit ideas...,</Typography><Typography> please use the form below.</Typography>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <TextField id="Feedback field" variant="outlined" fullWidth multiline 
            label={TextLabel}
            onChange={(e) => {
              SetTextComment(e.target.value);
            }}
            maxRows={7}
          >
            {TextComment}
          </TextField>
        </Box>
        <Stack direction="row" >
          <Button style={{"margin-left":"auto"}} flex={1} variant="outlined" disabled={!HasText} onClick={HandleSendFeedBack} ><SendIcon /> Send comment</Button>
        </Stack>
        {PostingStatus?<Alert severity={PostingStatus.Success?"success":"error"}>{PostingStatus.Text}</Alert>:null}
      </Stack>
    </FormControl>
  }
  
}
