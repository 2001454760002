import React, { useContext, useEffect, useState } from "react"

import { Autocomplete, Card, CardHeader, CardContent, Grid, TextField, Typography, Stack, Tooltip} from "@mui/material";


import { ReferenceUserContext } from './UserContextReference.js';
import { UserStatPlot } from "./UserStatPlot.js";
import { GetThemableClassName } from "./CssUtils.js";
import { useTheme } from "@emotion/react";

let CurDate = new Date()

function GetEverData( Row)
{
  return true;
}

function GetLastMonthData(Row)
{

  let Month = CurDate.getMonth();
  let Year = CurDate.getFullYear();

  if (Month === 0)
  {
    Month=11;
    Year -=1;
  }

  return (Row.Year===Year) && (Row.Month === Month);
}

function GetLastYearData(Row)
{
  return Row.Year === CurDate.getFullYear()-1;
}

function GetThisMonthData(Row)
{

  let Month = CurDate.getMonth()+1;
  let Year = CurDate.getFullYear();

  return (Row.Year===Year) && (Row.Month === Month);
}

function GetThisYearData(Row)
{
  return Row.Year === CurDate.getFullYear();
}

function GroupPerDay(Data)
{
  let RetTable=InitRetTable(1,31)

  for ( let index in Data)
  {
    if (Data[index])
    {
      let r = Data[index];
      RetTable[r.Day].Count+=r.Count;
      RetTable[r.Day].Duration+=parseInt(r.Duration,10);
    }
  }
  return RetTable
}

function GroupPerDayOfWeek(Data)
{
  let RetTable = InitRetTable(1,7);

  for ( let index in Data)
  {
    if (Data[index])
    {
      let r = Data[index];
      RetTable[r.DayOfWeek].Count+=r.Count;
      RetTable[r.DayOfWeek].Duration+=parseInt(r.Duration,10);
    }
  }
  return RetTable
}

function GroupPerHour(Data)
{
  let RetTable=InitRetTable(0,23)

  for ( let index in Data)
  {
    if (Data[index])
    {
      let r = Data[index];
      RetTable[r.Hour].Count+=r.Count;
      RetTable[r.Hour].Duration+=parseInt(r.Duration,10);
    
    }
  }
  return RetTable
}

function GroupPerMonth(Data)
{
  let RetTable=InitRetTable(1,12);

  for ( let index in Data)
  {
    if (Data[index])
    {
      let r = Data[index];
      RetTable[r.Month].Count+=r.Count;
      RetTable[r.Month].Duration+=parseInt(r.Duration,10);
    }
  }
  return RetTable
}

function GroupPerYear(Data)
{
  let RetTable=InitRetTable(2021,2021);

  for ( let index in Data)
  {
    if (Data[index])
    {
      let r = Data[index];
      if (typeof RetTable[r.Year]!== "undefined")
      {
        RetTable[r.Year].Count+=r.Count;
        RetTable[r.Year].Duration+=parseInt(r.Duration,10);
      }
      else
      {
        RetTable[r.Year]={Count:r.Count,Duration:parseInt(r.Duration,10)};
      }
    }
  }
  return RetTable;
}

function InitRetTable(Start,End)
{
  let RetTable={};

  for (let i=Start; i <=End; i++)
  {
    RetTable[i]={Count:0,Duration:0};
  }

  return RetTable;
}


const Span=["Ever", "LastYear","This year", "Last Month", "This Month","Last Week",'This Week',"yesterday",'Today'];
const Groups=["Year", "Month", "Day", "Day of Week","Hour"];

export function TrackCountStats ()
{
  const Theme=useTheme()
  const Options=[
    // Ever
    {Group: 0,Section:0},{Group: 0,Section:1},{Group: 0,Section:2} ,{Group: 0,Section:3} ,
    // Last Year
    {Group: 1,Section:1},{Group: 1,Section:2} ,{Group: 1,Section:3} ,{Group: 1,Section:4} ,
    // This Year
    {Group: 2,Section:1},{Group: 2,Section:2} ,{Group: 2,Section:3} ,{Group: 2,Section:4} ,
    // Last Month
    {Group: 3,Section:2} ,{Group: 3,Section:3} ,{Group: 3,Section:4} ,
    // This Month
    {Group: 4,Section:2} ,{Group: 4,Section:3} ,{Group: 4,Section:4} ,
    // Last Week
    {Group: 5,Section:2} ,{Group: 5,Section:3} ,{Group: 5,Section:4} ,
    // This Week
    {Group: 6,Section:2} ,{Group: 6,Section:3} ,{Group: 6,Section:4} ,
    // Yesterday
    {Group: 6,Section:4} ,
    // Today
    {Group: 7,Section:4} ,
    
    
  ]
  
  const EmptyStats=() => { return (<Grid item><Typography>No  stats yet...</Typography></Grid>);};
  const {ContextState}=useContext(ReferenceUserContext);
  let PlotStartGroup=localStorage.getItem("PlotOptionGroup");
  let PlotStartSection=localStorage.getItem("PlotOptionSection");
  let StartState={
        Group:(PlotStartGroup==null)?0:PlotStartGroup,
        Section:(PlotStartSection==null)?0:PlotStartSection,
      }
  const [StatType,SetStatType]=useState(StartState);
  const [Plot,SetPlot]=useState(EmptyStats());
  const [Totals,SetTotals]=useState(null);
  
  function HandleComboChange(event, newValue)
  {
    SetStatType(newValue);
    localStorage.setItem("PlotOptionGroup",newValue?.Group);
    localStorage.setItem("PlotOptionSection",newValue?.Section);    
  }

  useEffect (()=>
    {

      function BuildGroupedPlot (GroupedData)
      {
        let SumDuration=0;
        let SumCount=0;
        let Count = 0;
        let PlotData =[]

        for (let i in GroupedData)
          {
            Count+=1;
            SumDuration+=GroupedData[i].Duration;
            SumCount+=GroupedData[i].Count;
            PlotData.push({x:parseInt(i,10),y:GroupedData[i].Count});
          }

        return [PlotData,SumDuration,SumCount,Count]  
      }
      let newValue=StatType;
      let PlotData,PlotDataPrev
      let SumDuration=0;
      let SumCount=0;
      let Count = 0;
        
      if (newValue)
      {
        const Filters=[ GetEverData,GetLastYearData,GetThisYearData,GetLastMonthData,GetThisMonthData]
        const FiltersPrev=[ null,null,GetLastYearData,null,GetLastMonthData]
        const GroupFunctions = [GroupPerYear, GroupPerMonth, GroupPerDay, GroupPerDayOfWeek, GroupPerHour]
          
        if (ContextState?.Stats && ContextState.Stats?.TrackCountPlot && newValue && Filters[newValue.Group] && GroupFunctions[newValue.Section])
        {
          let SrcData=ContextState.Stats.TrackCountPlot.filter(Filters[newValue.Group]);
          let GroupedData = GroupFunctions[newValue.Section](SrcData);
          [PlotData,SumDuration,SumCount,Count]=BuildGroupedPlot(GroupedData);
          let MainPlotInfo={Points:PlotData,Line:{stroke: 'lightblue', strokeWidth: 2},Dot:"dot",LegendName:"Current"}
          let PrevPlotInfo=null
          
          if (FiltersPrev && FiltersPrev[newValue.Group])
          {
            let SrcData2=ContextState.Stats.TrackCountPlot.filter(FiltersPrev[newValue.Group]);
            let GroupedDataPrev = GroupFunctions[newValue.Section](SrcData2);
            [PlotDataPrev]=BuildGroupedPlot(GroupedDataPrev);
            PrevPlotInfo={Points:PlotDataPrev,Line:"none",FillValue:"#FF0000",Dot:null,LegendName:"Previous"}
          }
          
          let AvgDuration = SumDuration / SumCount;
          let AvgCount = SumCount / Count;

                            
          SetPlot ( 
                <Card xs={12} md={8} sx={{flex:6}}>                  
                  <CardHeader  title={Span[StatType.Group]+ ' / ' + Groups[StatType.Section]}/>
                  <CardContent>
                    <UserStatPlot Datas={[MainPlotInfo ,PrevPlotInfo]} />                         
                  </CardContent>
                </Card>);

          SetTotals({TotalDuration:SumDuration, TotalCount:SumCount, AvgDuration:AvgDuration, AvgCount:AvgCount});
            
        }
            
      }
      else
      {
        SetPlot(<Typography variant="caption">"Select time period & grouping mode"</Typography>); 
        SetTotals(null);
      }

      
    },
    [StatType,ContextState?.Stats ] );

  let TotalInfo=null;

  if (Totals && StatType)
  {
    
    function TotalLine (Props)
    {
      const HM = require("humanize-duration");
      const {Duration} = require ('luxon');
      let Value = Props.Value;
      if (isNaN(Value))
      {
        Value = 0
      }
      let Label = Props.Label;
      let Rounding = Props.Round?Props.Round:2;
      let Scale = Props.Scale?Props.Scale:1;
      let TooltipText= HM(Duration.fromObject({seconds:Value}),
        {
          delimiter: " ",
          spacer: "",
          language: "shortEn",
          languages: {
            shortEn: {
              y: () => "Y",
              mo: () => "M",
              w: () => "w",
              d: () => "d",
              h: () => "h",
              m: () => "m",
              s: () => "s",
              ms: () => "ms", 
            },
            
          },
        })

      return (
          <Tooltip title={TooltipText} arrow placement="right">
            <Typography variant="body2">{Label} : {(Value/Scale).toFixed(Rounding)}</Typography>
          </Tooltip>
          
        
      )
    }
    TotalInfo=
      <Card >
        <CardHeader title="Totals"/>
        <CardContent>
          <Stack>
            <Typography variant="body2">Total Tracks listened : {Totals.TotalCount}</Typography>
            <Typography variant="body2">Average Track Count per {Groups[StatType.Section]} : {Totals.AvgCount.toFixed(2)} </Typography>
            <TotalLine Label= "Total Duration listened (min)" Value= {Totals.TotalDuration} Scale = {60} Round={2}/>
            <TotalLine Label="Average Track Duration listened (min)" Value= {Totals.AvgDuration} Scale={60}/>
          </Stack>
        </CardContent>
      </Card>
  }
  return (
    <React.Fragment>
      <Card variant="outlined" >
        <CardHeader className={GetThemableClassName(Theme,"CardTitle")} title=  "Listened Tracks Charts"
        action={
          <Autocomplete
              options={Options}
              groupBy={(option) => Span[option.Group]}
              getOptionLabel={(option) => Groups[option.Section]}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} />}
              value={StatType}
              onChange={HandleComboChange}
              isOptionEqualToValue={((option, value) => 
                    {return (value.Group === option.Group) && (value.Section === option.Section)}
                    )}
              freeSolo={true}
          /> 
        }
        />
        <CardContent>
          <Stack direction={{xs:"column-reverse",lg:"row"}}  spacing={{xs:2,md:3}} alignContent="stretch">
                {Plot?Plot:EmptyStats()}
                {TotalInfo?TotalInfo:''}
                  
          </Stack>
        </CardContent>
        
      </Card>
    </React.Fragment>
  )

}